@import "../../node_modules/bootstrap/dist/css/bootstrap-grid.min.css";
@import "../../node_modules/bootstrap/dist/css/bootstrap-reboot.min.css";
@import "../../node_modules/swiper/dist/css/swiper.min.css";
@import "../../node_modules/ion-rangeslider/css/ion.rangeSlider.css";
@import "../../node_modules/ion-rangeslider/css/ion.rangeSlider.skinHTML5.css";
@import "../../node_modules/lightgallery.js/dist/css/lightgallery.min.css";

@import "../fonts/gothampro/stylesheet.css";
@import "../fonts/roboto/stylesheet.css";
@import "../fonts/robotocondensed/stylesheet.css";
/*
@import "../../node_modules/fancybox/dist/css/jquery.fancybox.css";
@import "../fonts/pfencoresanspro/stylesheet.css";
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.min.css";
@import "../../node_modules/owl.carousel/dist/assets/owl.theme.default.min.css";
@import "../../node_modules/magnific-popup/dist/magnific-popup.css";
*/
@font-face {
  font-family: 'lg';
  src: url("../fonts/lg.eot?n1z373");
  src: url("../fonts/lg.eot?#iefixn1z373") format("embedded-opentype"), url("../fonts/lg.woff?n1z373") format("woff"), url("../fonts/lg.ttf?n1z373") format("truetype"), url("../fonts/lg.svg?n1z373#lg") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* шрифты для вышивки */
@font-face {
  font-family: 'AGCENB';
  src: url("../fonts/AGCENB.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Arial';
  src: url("../fonts/arial.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Ariston';
  src: url("../fonts/Ariston.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Ciyrillic';
  src: url("../fonts/CyrillicOld.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Majestic';
  src: url("../fonts/Majestic-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Mon-Amour';
  src: url("../fonts/mon-amour-one-medium_[allfont.ru].ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Mtcorsva';
  src: url("../fonts/MTCORSVA.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Pompadur';
  src: url("../fonts/Pompadur.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Vivaldid';
  src: url("../fonts/VIVADCL_.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* стили для input[type=range] */
/* контейнер */
/* #secondRange {
  -webkit-appearance: none;
  appearance: none;
  width: 260px;
  height: 10px;
  padding: 0;
  box-shadow: inset 0 0 0 2px #2D7CFA;
  background: #AD020D;
  
  overflow: visible;
} */
/* Линия-ндикатор для Firefox */
/* #secondRange::-moz-range-track {
  background: none;
  border: none;
} */
/* ползунок в Firefox */
/* #secondRange::-moz-range-thumb {
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 70%/30%;
  border: 2px solid #818181;
  box-shadow:
      -13px 0 #4A0101, -26px 0 #4A0101, -39px 0 #4A0101,
      -52px 0 #4A0101, -65px 0 #4A0101, -78px 0 #4A0101,
      -91px 0 #4A0101, -104px 0 #4A0101, -117px 0 #4A0101,
      -130px 0 #4A0101, -143px 0 #4A0101, -156px 0 #4A0101,
      -169px 0 #4A0101, -182px 0 #4A0101, -195px 0 #4A0101,
      -208px 0 #4A0101, -221px 0 #4A0101, -234px 0 #4A0101,
      -247px 0 #4A0101, -260px 0 #4A0101;
} */


/* ползунок в Хроме */
/* #secondRange::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 2px solid #818181;
  background: #fff;
  box-shadow:
      -13px 0 #4A0101, -26px 0 #4A0101, -39px 0 #4A0101,
      -52px 0 #4A0101, -65px 0 #4A0101, -78px 0 #4A0101,
      -91px 0 #4A0101, -104px 0 #4A0101, -117px 0 #4A0101,
      -130px 0 #4A0101, -143px 0 #4A0101, -156px 0 #4A0101,
      -169px 0 #4A0101, -182px 0 #4A0101, -195px 0 #4A0101,
      -208px 0 #4A0101, -221px 0 #4A0101, -234px 0 #4A0101,
      -247px 0 #4A0101, -260px 0 #4A0101; 
} */
/* конец стили для input[type=range] */